<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="sectores"
                  item-text="nombre"
                  item-value="id"
                  label="Sectores"
                  v-model="sector"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="tiposcontratacion"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo de Contratación"
                  v-model="tipocontratacion"
                ></v-select>
              </v-col>

              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "ContratacionesFilter",
  data() {
    return {
      estados: [
        { id: 1, nombre: "Activos" },
        { id: 0, nombre: "Inactivos" },
        { id: 2, nombre: "Todos" },
      ],
      estado: 1,
      sectores: [],
      sector: 0,
      tipocontratacion: 0,
      tiposcontratacion: [],
      search: "",

      empleados: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    EmpleadosService.getSectores()
      .then((response) => {
        this.sectores = response.data.results;
        this.sectores.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
    PresentismoService.getTiposContratacion()
      .then((response) => {
        this.tiposcontratacion = response.data.results;
        this.tiposcontratacion.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      PresentismoService.getContrataciones(
        this.search,
        this.tipocontratacion,
        this.sector,
        page
      )
        .then((response) => {
          this.empleados = response.data;
          this.$emit("empleados-changed", this.empleados);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>

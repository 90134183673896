<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Contrataciones de Empleados</span>
      </v-col>
      <v-col>
        <v-btn to="/contrataciones/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <ContratacionesFilter
          @empleados-changed="sendempleados"
          :pageNumber="pageNumber"
        >
        </ContratacionesFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <ContratacionesTable
          ref="contratacionesTable"
          :empleados="this.empleados.results"
          :empleadosCount="this.empleados.count"
          @paginate="handlePagination"
        >
        </ContratacionesTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContratacionesTable from "../components/ContratacionesTable.vue";
import ContratacionesFilter from "../components/ContratacionesFilter.vue";

export default {
  name: "Contrataciones",
  components: {
    ContratacionesTable,
    ContratacionesFilter,
  },
  data() {
    return {
      ingresos: null,
      empleados: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendempleados(empleados) {
      // this.pageNumber = "1";
      this.empleados = empleados;
      this.$refs.contratacionesTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>

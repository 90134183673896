<template>
  <div v-if="empleados">
    <v-data-table
      :headers="headers"
      :items="empleados"
      item-key="id"
      class="elevation-1"
      locale="es-AR"
      no-data-text="No hay datos para mostrar"
      :header-props="headerProps"
      :footer-props="footerProps"
      mobile-breakpoint="500"
      @click:row="selectRow"
      disable-sort
      :server-items-length="empleadosCount"
      hide-default-footer
      :page.sync="page"
      :loading="loading"
      loading-text="Cargando... Espere por favor."
      @update:page="pageUpdateFunction"
    >
      <template #item.documento="{ item }"
        >{{ item.get_documento_tipo_display }} -
        {{ item.documento_nro }}</template
      >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="15"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContratacionesTable",
  data() {
    return {
      search: "",
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: "Filas por página",
      },
      itemsPerPage: 10,
      page: 1,
      loading: false,
    };
  },
  props: {
    empleados: {
      type: Array,
      required: false,
    },
    empleadosCount: {
      type: Number,
      required: false,
    },
  },
  methods: {
    pageUpdateFunction(newPageNumber) {
      this.loading = true;
      this.empleados = [];
      this.$emit("paginate", newPageNumber.toString());
    },

    selectRow(event) {
      this.$router.push({
        name: "EmpleadoDetalle",
        params: { id: event.id },
      });
    },
  },

  computed: {
    pageCount: function () {
      if (this.empleadosCount < 10) return 1;
      return Math.ceil(this.empleadosCount / this.itemsPerPage);
    },
    headers() {
      return [
        {
          text: "Apellidos",
          align: "start",
          sortable: true,
          value: "empleado.apellidos",
        },
        {
          text: "Nombres",
          align: "left",
          sortable: true,
          value: "empleado.nombres",
        },
        {
          text: "Documento",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "empleado.documento_nro",
        },
        {
          text: "Legajo",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "empleado.legajo",
          default: "--",
        },
        {
          text: "Sector",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "empleado.sector_display.nombre",
          default: "--",
        },
        {
          text: "Tipo Contratación",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "tipo.nombre",
          default: "--",
        },
      ];
    },
  },
};
</script>
